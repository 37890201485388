<template>
  <div>
    <the-app-bar />
    <healthhub-search />
    <Footer />
  </div>
</template>

<script>
import Footer from "../../../components/footer.vue";
import HealthhubSearch from "../../../components/HealthHub/HealthhubSearch.vue";
import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
export default {
  components: { TheAppBar, Footer, HealthhubSearch }
};
</script>

<style lang="scss" scoped></style>
